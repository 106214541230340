import React from "react"
import Seo from "../app/services/seo"
import Layout from "../app/layouts/default"


import Breadcrumbs from "../app/components/breadcrumbs";
import data from '../data/about-us/index.json';
import Hero from "../app/components/hero";


import * as Megacontent from "../app/helpers/megacontent";

const breadcrumbs = [
    {
        link: '/',
        label: 'Home'
    },
    {
        label: 'Chi Siamo'
    }

];
const { hero, dynamic, gallery, triplet } = data;

const AboutUsPage = (props) => {
    return (
        <Layout noMarginFooter={true}>
            <Seo title="About Us page" keywords={[`gatsby`, `application`, `react`]} />
            <div className="container">
                <Breadcrumbs homeLabel={'Home'} items={breadcrumbs} />
            </div>
            <Hero
                image={hero.image}
                imageMobile={hero.imageMobile}
                title={hero.title}
                type={hero.type}
            >
                {hero.description}
            </Hero>
            <Megacontent.Gallery introTitle={gallery.introTitle} introText={gallery.introText} items={gallery.items} />
            <Megacontent.Dynamic introTitle={dynamic.introTitle} items={dynamic.items} />
            <Megacontent.Triplet items={triplet.items} />
            <Megacontent.EditorialAboutUs>
                <h2>Haier Europe</h2>
                <p>Haier Europe fa parte di Haier Smart Home, il marchio n. 1 al mondo nel mercato degli elettrodomestici e tra le società Fortune Global 500. Quotata nella Borsa di Shanghai (SHA: 600690) dal 1993, Haier Smart Home è presente in tutti i 5 continenti con 25 impianti industriali, 10 centri di ricerca e sviluppo e circa 100.000 dipendenti. Con un fatturato di 25 miliardi di euro nel 2019, la rete di vendita globale dell'azienda copre più di 160 paesi. La vision di Haier è diventare il leader globale nell'IoT per gli smart home appliances. Haier Smart Home è la prima azienda cinese ad entrare nel mercato D-Share del China Europe International Exchange (CEINEX D-Share Market) a Francoforte nell'ottobre 2018 con l'obiettivo di promuovere il brand e supportare la crescita del business a livello internazionale e in Europa, dove l'azienda conta su marchi di successo quali Candy, Hoover, Haier, Rosières, GE Appliances e Fisher & Paykel. La sede europea dell'azienda si trova a Brugherio (MB), Italia.</p>
                <ul>
                    <li>Candy Hoover Group Srl</li>
                    <li>Brugherio (MB) - Via Privata Eden Fumagalli, 20861</li>
                    <li>Vimercate (MB) - Via Trento 22, 20871</li>
                    <li>P. Iva IT00786860965</li>
                </ul>
            </Megacontent.EditorialAboutUs>
        </Layout>
    )
};

export default AboutUsPage;
